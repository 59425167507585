import * as contentful from 'contentful'
import type { Restaurant } from '@/types/api/restaurant'
import { GRILLD_URL } from '~/constants/url'
import type { TypeFooterSkeleton, TypeHeaderSkeleton, TypePageSkeleton } from '~/types/contentful'

/**
 * @description Dynamically generate Organisation Schema
 * @returns Organisation Schema JSON linking data
 */
export const dynamicOrganisationSchema = (
  pageData: contentful.Entry<TypePageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | null,
  {
    footer,
    header
  }: {
    footer: contentful.Entry<TypeFooterSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
    header: contentful.Entry<TypeHeaderSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
  }
) => {
  const formattedlLogoUrl = () => {
    const logo = header.fields.logo?.fields.file?.url
    const formattedLogo = logo ? (logo.includes('https:') ? logo : `https:${logo}`) : ''
    return formattedLogo
  }

  const socialLinkArray = footer.fields.socials?.map((el) => {
    return el?.fields.href
  })

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'Grill’d',
    'url': GRILLD_URL ?? '',
    'description': pageData?.fields.seo?.fields.metaDescription ?? '',
    'logo': formattedlLogoUrl(),
    'alternateName': 'Grilld',
    'sameAs': socialLinkArray,
    'contactPoint': [
      {
        '@type': 'ContactPoint',
        'url': 'https://grilld.zendesk.com/hc/en-au/requests/new',
        'contactType': 'General Enquiries',
        'areaServed': 'AU'
      }
    ]
  }
}

/**
 * @description Dynamically generate LocalBusiness Schema
 * @returns LocalBusiness Schema JSON linking data
 */
export const dynamicLocalBusinessSchema = (restaurantData: Restaurant, slugString: string) => {
  const addressArray = restaurantData.address.split(',')

  const openingHours = restaurantData.hours.map((el: any) => {
    const openTimeArray = el.description.split('-')
    return {
      '@type': 'OpeningHoursSpecification',
      'dayOfWeek': el.name ?? '',
      'opens': convertTo24HourFormat(openTimeArray[0]),
      'closes': convertTo24HourFormat(openTimeArray[1])
    }
  })

  const restaurantUrl = slugString ? `${GRILLD_URL}/restaurants/${slugString}` : ''

  return {
    '@context': 'https://schema.org',
    '@type': 'Restaurant',
    'address': {
      '@type': 'PostalAddress',
      'addressLocality': addressArray[1] ?? '',
      'addressRegion': addressArray[2] ?? '',
      // TODO: set dynamic data to postcode when it returns from API
      // 'postalCode': '',
      'streetAddress': addressArray[0] ?? ''
    },
    // TODO: Add dynamic value when data is ready from API
    // "aggregateRating": {
    //   "@type": "AggregateRating",
    //   "ratingValue": "3.7",
    //   "reviewCount": "101"
    // },
    'name': restaurantData.name ?? '',
    'openingHoursSpecification': openingHours,
    'priceRange': '$20-$40',
    'servesCuisine': [
      'Burgers, Healthy Burgers, Healthy Fried Chicken Burgers, Vegetarian Burgers, Vegan Burgers, Beef Burgers'
    ],
    'telephone': restaurantData.phoneNumber ?? '',
    'url': restaurantUrl
    // TODO: Add dynamic value when data is ready from API
    // "sameAs": []
  }
}

function convertTo24HourFormat(timeString: string) {
  if (!timeString) return ''

  const timeStrTrimed = timeString.trim()
  const [time, period] = [timeStrTrimed.slice(0, -2), timeStrTrimed.slice(-2)]

  let hour, minute

  if (time.includes(':')) {
    ;[hour, minute] = time.split(':')
  } else {
    hour = time
    minute = '00'
  }

  let formattedHour = parseInt(hour)

  if (period === 'PM') {
    formattedHour += 12
  }

  return `${formattedHour}:${minute}`
}
